
import Vue from "vue";
import ChatContainer from "@/components/ChatContainer.vue";

export default Vue.extend({
  name: "ConversationsPage",
  components: {
    ChatContainer
  }
});
